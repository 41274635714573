//---------- config ----------
var SITE = {};
SITE.config = window.SITE_config || {};
SITE.config.devMode = SITE.config.devMode || false;
SITE.config.baseUrl = SITE.config.baseUrl || window.location.protocol + '//' + window.location.hostname;
SITE.config.scrollSpeed = 500;
SITE.cssTransitionTimeMs = 500;
SITE.cssTransitionTimeFastMs = 250;
SITE.mobileMenu = false;
SITE.breakpointMobile = 1024;
SITE.menuMobileHeight = 50;
SITE.scrollReveal = false;

//---------- helpers ----------
SITE.helpers = {

};

//---------- components ----------
/**
 * SITE components.
 * @type {{init: Function}}
 */
SITE.components = {
    alreadyInitialized: false,
    smoothScroll: function() {
        var currentPage = window.location.protocol+'//'+window.location.hostname+window.location.pathname;
        var currentPageRelative = window.location.pathname;

        $('a[href^="#"], [href^="'+currentPage+'#"], [href^="'+currentPageRelative+'#"]').smoothScroll({
            speed: SITE.config.scrollSpeed,
            preventDefault: true
        });
    },
    lightboxes: function() {
        //----------------- magnific popup videos
        var magnificVideosSelector = "a[href*='youtu.be\/'], a[href*='youtube.com'], a[href*='vimeo.com']";
        var magnificVideoOptions = {
            type: 'iframe',
            closeOnContentClick: false,
            closeOnBgClick: true,
            callbacks: {
                open: function () {
                    if ( SITE.mobileMenu ) {
                        SITE.mobileMenu.close();
                    }
                }
            },
            iframe: {
                patterns: {
                    youtube_short: {
                        index: 'youtu.be/',
                        id: 'youtu.be/',
                        src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
                    },
                    youtube_embeds: {
                        index: 'youtube.com/embed',
                        id: 'embed/',
                        src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
                    },
                    youtube: {
                        index: 'youtube.com/watch',
                        id: 'v=',
                        src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
                    },
                    vimeo: {
                        index: 'vimeo.com/',
                        id: function(src){
                            if (src.indexOf('external') > -1) {
                                return 'external/' + src.substr(src.lastIndexOf('/')+1, src.length);
                            } else {
                                return 'video/' + src.substr(src.lastIndexOf('/')+1, src.length);
                            }
                        },
                        src: '//player.vimeo.com/%id%?autoplay=1'
                    }
                }
            },
            gallery: {
                enabled: false
            }
        };
        $(magnificVideosSelector).magnificPopup(magnificVideoOptions);

        //----------------- magnific popup images
        var magnificImagesSelector = 'a[href$=".gif"], a[href$=".jpg"], a[href$=".png"]';
        var magnificIgnoreSelector = '.no-lightbox';
        var magnificImageOptions = {
            type: 'image',
            closeOnContentClick: false,
            closeOnBgClick: true,
            callbacks: {
                open: function () {
                    if ( SITE.mobileMenu ) {
                        SITE.mobileMenu.close();
                    }
                }
            },
            gallery: {
                enabled: false
            },
            mainClass: 'mfp-with-zoom mfp-fade',
            removalDelay: SITE.cssTransitionTimeMs,
            zoom: {
                enabled: true,
                duration: 300,
                easing: 'ease-in-out',
                opener: function(openerElement) {
                    if ( openerElement.is('img') ) {
                        return openerElement;
                    } else {
                        var $img = openerElement.find('img');
                        if ($img.length) {
                            if ( !! $img.attr('src')) {
                                return $img;
                            }
                        }
                    }

                    return openerElement;
                }
            }
        };
        $(magnificImagesSelector).filter(':not('+magnificIgnoreSelector+')').magnificPopup(magnificImageOptions).addClass('zoom-in');

        //----------------- magnific popup mixed galleries
        //combine the video and image settings
        var magnificGalleryOptions = $.extend({}, magnificVideoOptions, magnificImageOptions);
        //add callback to dynamically determine the type
        magnificGalleryOptions.callbacks.elementParse = function( item ) {
            if ( $(item.el).is( magnificVideosSelector) ) {
                item.type = 'iframe';
            } else {
                item.type = 'image';
            }
        };

        //enable gallery mode
        magnificGalleryOptions.gallery = {
            enabled: true,
            navigateByImgClick: true,

            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

            tPrev: 'Previous (Left arrow key)', // title for left button
            tNext: 'Next (Right arrow key)', // title for right button
            tCounter: '<span class="mfp-counter">%curr% of %total%</span>' // markup of counter
        };
        //disable zoom so that videos won't throw an error
        //magnificGalleryOptions.zoom = { enabled: false };
        magnificGalleryOptions.mainClass = 'mfp-fade';

        $('[data-component=gallery]').each( function(){
            $(this).find('[data-component=gallery-item]').magnificPopup( magnificGalleryOptions );
        });
    },
    lightboxInline: function() {
        $('[data-lightbox-inline]').magnificPopup({
            type:'inline',
            midClick: true,
            mainClass: 'mfp-fade',
            removalDelay: SITE.cssTransitionTimeMs
        });
    },
    slider: function() {
        $('#pjax-container').find('[data-slider]').each(function(ind,slider){
            var $slider = $(slider);
            if ( ! $slider.hasClass('slick-initialized') ) {
                $slider.slick({
                    autoplay: true,
                    autoplaySpeed: 4000,
                    pauseOnHover: false,
                    arrows: false,
                    dots: false,
                    swipe: false,
                    infinite: true,
                    speed: 2000,
                    fade: true,
                    adaptiveHeight: false
                });

                setTimeout(function(){
                    $slider.find('.slide').removeClass('lazyloadWithFade');
                }, 500);
            }
        });
    },
    /**
     * Replace SVG images with actual SVGs.
     * Idea base on: http://stackoverflow.com/a/11978996/1136822
     * @param el
     */
    svgImageToSvg: function(el) {
        $el = $(el);
        if ( $el.length ) {
            $el.find('img[src$=".svg"]').each(function(ind,img){
                var $img = $(img);
                var imgID = $img.attr('id');
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');
                var imgMaxHeight = $img.attr('data-height-max');

                $.get(imgURL, function(data) {
                    var $svg = $(data).find('svg');

                    //add id
                    if(typeof(imgID) != 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    //add classes
                    if(typeof(imgClass) != 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg');
                    }
                    //add max height
                    if(typeof(imgMaxHeight) != 'undefined') {
                        $svg = $svg.attr('data-height-max', imgMaxHeight);
                    }

                    //remove invalid XML tags http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    //replace image with new SVG
                    $img.replaceWith($svg);

                    //scalable svg handling
                    SITE.components.scalableSvgsManager().addSvg($svg.get(0));
                }, 'xml');
            });
        }
    },
    replaceSvgImagesWithSvg: function() {
        $('[data-component=replace-svg-images]').each(function(ind,holder){
            SITE.components.svgImageToSvg(holder);
        });
    },
    scalableSvgsManager: function() {
        var manager = this;
        manager.$svgWrappers = $([]);

        manager.addSvg = function( svg ) {
            var $svg = $(svg);
            if ($svg.length ) {

                //determine the width, height, and aspect ratio
                var bounding;
                var width = 0;
                var height = 0;
                var attrWidth = $(svg).attr('data-width');
                var attrHeight = $(svg).attr('data-height');

                if ( attrWidth && attrHeight ) {
                    width = attrWidth;
                    height = attrHeight;
                } else if (typeof(svg.getBoundingClientRect) == 'function') {
                    bounding = svg.getBoundingClientRect();
                    width = bounding.width;
                    height = bounding.height;
                } else if (typeof(svg.getBBox) == 'function') {
                    bounding = svg.getBBox();
                    width = bounding.width;
                    height = bounding.height;
                }

                $svg
                    .attr('data-aspectRatio', height / width)
                    .removeAttr('height')
                    .removeAttr('width');

                if ( ! $svg.parent('.responsiveSvg').length ) {
                    $svg.wrap( '<div class="responsiveSvg responsiveSvg-notResized">' );
                }
                manager.$svgWrappers = manager.$svgWrappers.add($svg.parent() );
                manager.resizeAll();
                $svg.one('load',manager.resizeAll);
                setTimeout(manager.resizeAll, 200);
                setTimeout(manager.resizeAll, 1000);
            }
        };
        manager.resizeAll = function() {
            if ( ! manager.$svgWrappers.length ) {
                return;
            }

            manager.$svgWrappers.each(function(ind,ele){
                //svg wrapper
                var $svgWrapper = $(ele);
                if ( ! $svgWrapper.length ) {
                    return true;
                }

                //svg
                var $svg = $svgWrapper.find('> svg').eq(0);
                if ( ! $svg.length  ) {
                    return true;
                }

                //new width
                var newWidth = $svgWrapper.width();
                var newHeight = newWidth * $svg.attr('data-aspectRatio');

                //limit by max height, if applicable
                var maxHeight = $svg.attr('data-height-max');
                if ( maxHeight && maxHeight < newHeight ) {
                    newHeight = maxHeight;
                    newWidth = newHeight / $svg.attr('data-aspectRatio')
                }

                var resizeHandler = $svg.attr('data-resize-handler');
                if ( resizeHandler ) {
                    if ( resizeHandler == 'building' ) {
                        var screenTop = head.screen.innerWidth > SITE.midBreakpoint ? 180 : 115;
                        var screenBottom = 40;
                        maxHeight = head.screen.innerHeight - screenTop - screenBottom;
                        if ( maxHeight && maxHeight < newHeight ) {
                            newHeight = maxHeight;
                            newWidth = newHeight / $svg.attr('data-aspectRatio')
                        }
                    }
                }

                $svgWrapper.removeClass('responsiveSvg-notResized');

                $svg
                    .width(newWidth)
                    .attr('data-width-current', newWidth)
                    .height(newHeight)
                    .attr('data-height-current', newHeight)
            });

            $.publish('svgSizeChanged');
        };

        $(window).on('DOMContentLoaded load resize', manager.resizeAll );

        return manager;
    },
    mobileMenu: function() {
        var $window = $(window),
            $html = $('html').eq(0),
            $header = $('.header').eq(0),
            mobileMenuIsOpen = false,
            openedClass = 'mobileMenuOpen',
            $mobileMenuHolder = $('[data-mobile-menu-holder]').first();

        if ( $mobileMenuHolder.length ) {
            var menuSelector = $mobileMenuHolder.attr('data-mobile-menu-holder');

            SITE.mobileMenu = new MobileMenu(false, {
                setup: function (el) {
                    var $nav = $('<div class="mobileMenu">'+$(menuSelector).eq(0).prop('outerHTML')+'</div>');
                    $nav.find('ul').removeAttr('class');
                    $mobileMenuHolder.append($nav);
                },
                onOpen: function (el) {
                    $html.addClass(openedClass);
                },
                onClose: function (el) {
                    $html.removeClass(openedClass);
                }
            });

            $window.on('DOMContentLoaded load resize', debounce(function () {
                //close menu if needed
                var innerWidth = Math.max($(window).width(), window.innerWidth);
                if (innerWidth >= SITE.breakpointMobile) {
                    SITE.mobileMenu.close();
                }
            }, 250, false));

            //open button
            var $mobileMenuToggle = $('[data-component=mobileMenuToggle]');
            $mobileMenuToggle.click(function (e) {
                if (mobileMenuIsOpen) {
                    SITE.mobileMenu.close();
                } else {
                    SITE.mobileMenu.open();
                }
                mobileMenuIsOpen = !mobileMenuIsOpen;
            });

            //close if a link is clicked
            $header.on('click', 'a', function (e) {
                SITE.mobileMenu.close();
            });
        }
    },
    floatingMenu: function() {
        var $window = $(window),
            $menus = $('[data-floating-menu-distance]').first();
        if ( $menus.length ) {
            $menus.each(function(ind,menu){
                var $menu = $(menu);
                var offset = $menu.attr('data-floating-menu-distance');
                var $spacer = $('<div class="floating-menu-spacer"></div>');
                var menuHeight = $menu.outerHeight();
                $menu.after($spacer);

                var positionNav = function() {
                    if ($window.scrollTop() >= offset) {
                        $menu.addClass('floating-nav');
                        $spacer.css('height', menuHeight);
                    } else {
                        $menu.removeClass('floating-nav');
                        $spacer.css('height', 0);
                    }
                };
                $window.on('DOMContentLoaded load resize scroll', debounce(positionNav, 10, false) );
            });
        }
    },
    expandAndContract: function() {
        $('[data-component=expandAndContract]').each(function(ind,holder){
            var $holder = $(holder);
            var $expanders = $holder.find('[data-component=expandAndContract-expand]');
            var $expandables = $holder.find('[data-component=expandAndContract-expandable]');
            var $contracters = $holder.find('[data-component=expandAndContract-contract]');
            var $html = $('html').eq(0);

            //expand
            $expanders.click(function() {
                $holder.addClass('expandAndContract--expanded');
                $expandables.velocity("slideDown", {duration: SITE.cssTransitionTimeMs});

                if ( $expandables.length ) {
                    $html
                        .velocity('stop')
                        .velocity('scroll', {
                            offset: $expandables.eq(0).offset().top + 200,
                            mobileHA:false,
                            duration:SITE.cssTransitionTimeMs
                        });
                }
            });

            //contract
            $contracters.click(function(){
                $holder.removeClass('expandAndContract--expanded');
                $expandables.velocity("slideUp", { duration: SITE.cssTransitionTimeMs });
            });
        });
    },
    parallax: function() {
        var $containers = $('[data-parallax-container]');
        if ( $containers.length ) {
            var $window = $(window);

            $containers.each(function(ind,holder){
                var $holder = $(holder);
                var $item = $holder.find('[data-parallax]');

                $window.on('scroll', function () {
                    window.requestAnimationFrame(function () {
                        var holderBox = holder.getBoundingClientRect()
                        //compensate for the mobile menu
                        var topOffset = 0;
                        //console.log(holderBox.height, $item.height(), $holder.scrollTop(), head.screen.innerHeight);

                        //todo fix when not at the top of the page

                        var pos = ((holderBox.height - $item.height()) * ( - (holderBox.top - topOffset) / head.screen.innerHeight));
                        pos = Math.round(pos);
                        if (pos > 0) {
                            pos = 0;
                        }
                        $item.css({transform: 'translate3d(0,' + pos + 'px, 0)'});
                    });
                });
            });
        }
    },
    responsiveVideos: function() {
        //based on http://css-tricks.com/NetMag/FluidWidthVideo/demo.php
        var $allVideos = $("iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com'], object, embed");
        $allVideos.each(function() {
            $(this)
            // jQuery .data does not work on object/embed elements
                .attr('data-aspectRatio', $(this).height() / $(this).width())
                .removeAttr('height')
                .removeAttr('width');

            if ( ! $(this).parent('.video-player').length ) {
                $(this).wrap( '<div class="video-player">' );
            }
        });
        function resizeVideos() {
            $allVideos.each(function() {
                var $el = $(this),
                    $parentEl = $el.parent('.video-player');
                if ( $parentEl.length )
                {
                    var newWidth = $parentEl.width();
                    $el
                        .width(newWidth)
                        .height(newWidth * $el.attr('data-aspectRatio'));
                }
            });
        }
        $(window).on('DOMContentLoaded load resize', debounce(resizeVideos, 100, false) );
        resizeVideos();
    },
    scrollReveal: function() {
        var $revealers = $('[data-revealer]');

        if ( $revealers.length ) {
            //load the needed files
            head.load({scrollReveal:'/assets/js/scrollreveal.min.js'});
            head.ready('scrollReveal', function() {
                $revealers.each(function(ind,revealer){
                    var $revealer = $(revealer);
                    var selector = $revealer.attr('data-revealer');
                    if ( ! selector ) {
                        return;
                    }

                    //play ball with pjax
                    $(selector)
                        .removeAttr('style')
                        .removeAttr('data-sr-id');

                    //kick off
                    SITE.scrollReveal = ScrollReveal();
                    SITE.scrollReveal.reveal(selector, {duration:SITE.cssTransitionTimeMs, easing: 'ease-out'});
                });
            });
        }
    },
    countdown: function() {
        var $apps = $('[data-component-countdown]');

        if ( $apps.length ) {
            //load the needed files
            head.load({plugin:'/assets/js/jquery.plugin.min.js'},{countdown:'/assets/js/jquery.countdown.min.js'});
            head.ready(['plugin', 'countdown'], function() {
                $apps.each(function(ind,app){
                    var $app = $(app);
                    var timestamp = $app.attr('data-component-countdown');
                    if ( ! timestamp ) {
                        return;
                    }

                    $app.countdown({until: new Date(timestamp*1000)});
                });
            });
        }
    },
    newsletterForm: function() {
        $('[data-component-newsletter-form]').each(function(ind,holder){
            var $holder = $(holder);
            var $form = $holder.find('form');
            //get the success div
            var $success = $holder.find('.newsletterForm-successMessage');

            //form submission handling
            $form.on('submit',function(e) {
                e.preventDefault();

                $.ajax({
                    type: "POST",
                    url: $form.attr('action').replace('post?', 'post-json?') + '&c=?',
                    cache: false,
                    dataType: 'jsonp',
                    data: $form.serialize(),
                    contentType: "application/json; charset=utf-8"
                })
                    .done(function (data) {
                        var message;
                        //hide the loader
                        $holder.removeClass('loading');

                        if (data.result != "success") {
                            if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
                                //message = "You're already subscribed. Thank you.";
                                $success.html(data.msg);
                                //show the message
                                $holder.addClass('newsletterForm--success');
                            } else {
                                //re-show the form
                                $form.removeClass('hidden');
                                message = data.msg || "Sorry. Unable to subscribe. Please try again later.";
                                message = message.replace(/^\d+ - /, '');
                                alert(message);
                            }
                        } else {
                            //show the success message
                            $success.html(data.msg);
                            $holder.addClass('newsletterForm--success');
                        }
                    });
            });
        });
    },
    charge: function() {
        $('[data-component-stripe]').each(function(ind,holder){
            var publicKey = $(holder).attr('data-component-stripe');
            Stripe.setPublishableKey(publicKey);
            $(this).charge();
        });
    },
    init: function() {
        SITE.components.mobileMenu();
        SITE.components.floatingMenu();
        SITE.components.slider();
        SITE.components.replaceSvgImagesWithSvg();
        SITE.components.smoothScroll();
        SITE.components.lightboxes();
        SITE.components.lightboxInline();
        SITE.components.expandAndContract();
        SITE.components.parallax();
        SITE.components.responsiveVideos();
        SITE.components.countdown();
        SITE.components.newsletterForm();
        SITE.components.charge();

        //always leave this one for last
        SITE.components.scrollReveal();
    }
};

//handle dom ready event
$(function domReady() {
    //initialize components
    SITE.components.init();
});
