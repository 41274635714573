var MobileMenu = function( el, options ){
    //set defaults
    this.el = el;
    this.onOpen = options.onOpen ? options.onOpen : function(){};
    this.onClose = options.onClose ? options.onClose : function(){};

    if ( options.setup ) {
        options.setup.call(this, this.el);
    }
};

MobileMenu.prototype.open = function() {
    this.onOpen.call(this, this.el);
};

MobileMenu.prototype.close = function() {
    this.onClose.call(this, this.el);
};